import { DatePicker, TimePicker } from "antd";
import { Guid } from "guid-typescript";
import _ from "lodash";
import moment from "moment";
import { PickerSharedProps } from "rc-picker/lib/Picker";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { CommonProps } from "../../../components/web-components/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKDatatimePickerProps extends PickerSharedProps<any> {
  value?: string;
  defaultValue?: string;
  format?: string;
  picker?: "time" | "date" | "week" | "month" | "quarter" | "year";
  display?: "Date" | "Time" | "Date & Time";
  showHour?: boolean;
  showMinute?: boolean;
  showSecond?: boolean;
  use12Hours?: boolean;
  hourStep?: number;
  minuteStep?: number;
  secondStep?: number;
  onChange?: (value: string) => void;
  style?: any;
  placeholder?: string;
  widthType?: "fill" | "content";
  disableBeforeDate?: any;
  className?: string;
}

interface IKDatatimePickerState {
  uniqueKey: Guid;
  isOpen: boolean;
}

declare let window: Window & { kuika: any };

class KDatatimePicker extends PureComponent<IKDatatimePickerProps & CommonProps, IKDatatimePickerState> {
  private memoizedDynamicCssResult = "";

  constructor(props: IKDatatimePickerProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create(),
      isOpen: false
    };
  }

  componentDidMount = () => {
    this.setDynamicStyle();
    if (this.props && this.props.style && this.props.style?.fontSize) {
      const node = ReactDOM.findDOMNode(this) as Element;
      const inputs = node.getElementsByTagName("input");
      if (inputs && inputs.length) {
        for (let index = 0; index < inputs.length; index++) {
          inputs[index].style.fontSize = this.props.style?.fontSize;
        }
      }
    }
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }

    let result = "";
    if (this.props.style?.color?.length > 0) {
      result +=
        `.${className.trim()} .ant-picker-input>input {
          ` +
        `color: ${this.props.style?.color}
        }`;
    }

    if (this.props.style?.backgroundColor?.length > 0 || this.props.style?.color?.length > 0) {
      result += `
          .${className.trim()} .ant-picker-clear {
            `;
      if (this.props.style?.backgroundColor?.length > 0)
        result += `background: ${this.props.style?.backgroundColor} !important;`;
      if (this.props.style?.color?.length > 0) result += `color: ${this.props.style?.color} !important;`;
      result += `;
      }`;
    }
    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kdatepicker_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  disabledDate = (current) => {
    const cur = _.cloneDeep(current);
    if (this.props.disableBeforeDate) {
      const value = moment(this.props.disableBeforeDate);
      if (value) {
        if (this.props.display && this.props.display != "Date") {
          return cur && cur < moment(value);
        }
        return cur.startOf("day") <= value.startOf("day").add(-1, "day");
      }
    }
    return undefined;
  };

  getWidth() {
    if (this.props.widthType && this.props.widthType == "content") {
      return "max-content";
    }
    return "100%";
  }

  convertToMoment = (value: string) => {
    return moment(value);
  };

  convertToString = (value: moment.Moment) => {
    if (value) {
      return value.format(this.getFormat());
    }
    return value;
  };

  getFormat = () => {
    if (this.props.display === undefined || this.props.display === "Date") {
      return "YYYY-MM-DD";
    }
    return "YYYY-MM-DDTHH:mm:ss";
  };

  handleChange = (value: any) => {
    const dateValue: any = this.convertToString(value);
    if (this.props.onChange) {
      this.props.onChange(dateValue);
    }
  };

  getGenericValue = () => {
    let result: any = {};
    if (this.props.value) {
      result = { value: this.convertToMoment(this.props.value) };
    }
    return result;
  };

  getGenericDefaultValue = () => {
    let result: any = {};
    if (this.props.defaultValue) {
      result = { defaultValue: this.convertToMoment(this.props.defaultValue) };
    }
    return result;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    let style: any = _.clone(this.props.style);
    if (props.defaultValue) {
      delete props.defaultValue;
    }
    if (props.disabledDate) {
      delete props.disabledDate;
    }
    if (props.value) {
      delete props.value;
    }
    if (_.isString(props.value) && props.value.length === 0) {
      delete props.value;
    }
    if (props.display) {
      delete props.display;
    }
    if (!style) {
      style = {
        width: this.getWidth()
      };
    } else if (!style.width) {
      style.width = this.getWidth();
    }
    if (style.display == "block") {
      style.display = "flex";
    } else if (style.display == "inline") {
      style.display = "inline-flex";
    }
    props.style = style;
    return props;
  };

  getDisplay = () => {
    if (this.props.display) {
      return this.props.display;
    }
    return "Date";
  };

  handleKeydown = (e: any) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode === 9 && this.state.isOpen) {
      this.setState({ isOpen: false });
      e.preventDefault();
      e.stopPropagation();
      const val = moment(e?.target?.value, this.props.format ?? "");
      if (val.isValid()) {
        this.handleChange(moment(val));
      }
      return false;
    }
    if (charCode === 9 && !this.state.isOpen) {
      if (this.props.onKeyDown) {
        this.props.onKeyDown(e, e.preventDefault());
      }
      return false;
    }
    const { format } = this.props;
    if (
      format !== "YYYY-MM-DD HH:mm:ss" &&
      format !== "DD-MM-YYYY HH:mm:ss" &&
      e.target.value.length === 10 &&
      charCode !== 8 &&
      charCode !== 46 &&
      charCode !== 186
    ) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    if (
      charCode > 31 &&
      (charCode > 57 || charCode < 48) &&
      (charCode > 105 || charCode < 96) &&
      charCode !== 8 &&
      charCode !== 46 &&
      charCode !== 186
    ) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    if (format === "YYYY/MM/DD" || format === "YYYY/DD/MM" || format === "YYYY-MM-DD HH:mm:ss") {
      this.addSlashToDateInput(e, 4, 7, charCode);
    } else if (format === "DD/MM/YYYY" || format === "MM/DD/YYYY" || format === "DD-MM-YYYY HH:mm:ss") {
      this.addSlashToDateInput(e, 2, 5, charCode);
    } else if (format === "MM/DD") {
      if (e.target.value.length === 5) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
      this.addSlashToDateInput(e, 2, undefined, charCode);
    } else if (format === "YYYY/MM") {
      if (e.target.value.length === 7) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
      this.addSlashToDateInput(e, 4, undefined, charCode);
    } else if (format === "D M Y") {
      e.target.value = e.target.value;
    } else if (format === undefined) {
      this.addSlashToDateInput(e, 4, 7, charCode);
    }
  };

  addSlashToDateInput = (e: any, firstLength: number, secondLength: number | undefined, charCode: number) => {
    if (e.target.value.length === firstLength && charCode !== 8 && charCode !== 46 && charCode !== 186) {
      e.target.value = `${e.target.value}/`;
    } else if (
      secondLength &&
      e.target.value.length === secondLength &&
      charCode !== 8 &&
      charCode !== 46 &&
      charCode !== 186
    ) {
      e.target.value = `${e.target.value}/`;
    } else e.target.value = e.target.value.replace(/[^0-9/]/g, "");
  };

  handleOpenChange = (isOpen: boolean) => {
    this.setState({ isOpen });
  };

  render(): ReactNode {
    const display = this.getDisplay();
    return (
      <>
        {(display === "Date" || display === "Date & Time") && (
          <DatePicker
            open={this.state.isOpen}
            onOpenChange={this.handleOpenChange}
            className={this.getClassName()}
            disabledDate={this.disabledDate}
            {...this.getProps()}
            showTime={display === "Date & Time" ? { defaultValue: moment("00:00:00", "HH:mm:ss") } : false}
            {...this.getGenericValue()}
            {...this.getGenericDefaultValue()}
            onChange={this.handleChange}
            onKeyDown={(e) => this.handleKeydown(e)}
          ></DatePicker>
        )}
        {display === "Time" && (
          <TimePicker
            open={this.state.isOpen}
            onOpenChange={this.handleOpenChange}
            className={this.getClassName()}
            secondStep
            {...this.getProps()}
            {...this.getGenericValue()}
            {...this.getGenericDefaultValue()}
            onChange={this.handleChange}
          ></TimePicker>
        )}
      </>
    );
  }
}
const kDatatimePicker = withCommonEvents(KDatatimePicker);
export { kDatatimePicker as KDatatimePicker };
